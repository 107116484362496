import {
  exportRequest,
  postBodyRequest
} from '@/utils/axios'

export function stockInPage (params) {
  return postBodyRequest('/trans/order/vehicle/stock/in', params)
}
export function stockInPageExport (params, fileName) {
  return exportRequest('/trans/order/vehicle/stock/export', params, fileName)
}
export function stocTransDispatchKanBanExport (params, fileName) {
  return exportRequest('/trans/order/vehicle/stock/trans/dispatch/kanBan/export', params, fileName)
}

export function stockInList (params) {
  return postBodyRequest('/trans/order/vehicle/stock/in/list', params)
}

export function transPage (params) {
  return postBodyRequest('/trans/order/vehicle/stock/trans', params)
}

export function transPageExport (params, fileName) {
  return exportRequest('/trans/order/vehicle/stock/trans/export', params, fileName)
}

export function transKanBan (params) {
  return postBodyRequest('/trans/order/vehicle/stock/trans/kanBan', params)
}

export function arrivePage (params) {
  return postBodyRequest('/trans/order/vehicle/stock/arrive', params)
}
export function arrivePageExport (params, fileName) {
  return exportRequest('/trans/order/vehicle/stock/arrive/export', params, fileName)
}

export function transKanBanPage (params) {
  return postBodyRequest('/trans/order/vehicle/stock/trans/kanBan/detail', params)
}

export function transKanBanDetailExport (params, fileName) {
  return exportRequest('/trans/order/vehicle/stock/trans/kanBan/export', params, fileName)
}
