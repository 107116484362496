<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form ref="formQueryRef" :model="searchForm">
        <a-row>
          <!-- <a-col :span="4">
            <a-form-item>
              <a-cascader :options="$store.state.app.cityDict" :changeOnSelect="true" :show-search="{ filter }" @change="setEndAddress" placeholder="请选择城市" />
            </a-form-item>
          </a-col> -->
          <a-divider type="vertical" />
          <a-col :span="6">
            <a-form-item label="时间">
              <a-space>
                <a-date-picker v-model:value="searchForm.startTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
                至
                <a-date-picker v-model:value="searchForm.endTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
              </a-space>
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-form-item>
              <a-select allowClear ref="select" v-model:value="searchForm.orgId" placeholder="归属区域">
                <a-select-option v-for="item in $store.state.app.orgList " :key="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-button type="primary" @click="onSearch">
              <template #icon>
                <SearchOutlined />
              </template>查询
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </template>
    <!-- <template #toolbarLeft>
      <a-form-item label="总数">
        <a-tag color="red">{{totalNum}}条</a-tag>
      </a-form-item>
    </template> -->
    <template #toolbarRight>
      <a-button @click="exportData">
        导出Excel
      </a-button>
    </template>
    <a-table :size="small" :columns="columns" :scroll="{ x:1500, y: 520}" :row-key="record => record.id" :data-source="listData" :loading="loading" :pagination="pagination" :rowKey="(record,index)=>{return index}" @change="handleChange">
      <template #total="{ record }">
        <span class=" total_font_size">
          {{record.total}}
        </span>
      </template>
      <template #pickTotal="{ record }">
        <span class="total total_font_size" @click="onDetail(4,record)">
          {{record.pickTotal}}
        </span>
      </template>
      <template #deliveryTotal="{ record }">
        <span class="ototal total_font_size" @click="onDetail(5,record)">
          {{record.deliveryTotal}}
        </span>
      </template>
      <template #startTotal="{ record }">
        <span class="ptotal total_font_size" @click="onDetail(1,record)">
          {{record.startTotal}}
        </span>
      </template>
      <template #transitTotal="{ record }">
        <span class="ttotal total_font_size" @click="onDetail(2,record)">
          {{record.transitTotal}}
        </span>
      </template>
      <template #endTotal="{ record }">
        <span class="dtotal total_font_size" @click="onDetail(9,record)">
          {{record.endTotal}}
        </span>
      </template>
    </a-table>
  </suy-table>
  <a-drawer title="发运管理" :width="drawerwid" :closable="true" :visible="visible" :maskClosable="true" @close="onSwitch(false)" v-if="visible" :zIndex="1000">
    <stockDetail ref="stockDetail" :cityDetail="cityDetail" :type="type" :cityIdDetail="cityIdDetail" :orgAllList="$store.state.app.orgTypeList" :startTime="searchForm.startTime" :endTime="searchForm.endTime" :rowNum="rowNum" :orgName="orgName"></stockDetail>
  </a-drawer>

</template>
<script>
import { transKanBan, stocTransDispatchKanBanExport } from '@/api/dataCenter/stock'
// import { getOrgList } from '@/api/global'
import { toRefs, ref, reactive, onMounted } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import SuyTable from '@/components/SuyTable'
import stockDetail from './detail'
export default {

  components: {
    SearchOutlined,
    SuyTable,
    stockDetail
  },
  setup () {
    const state = reactive({
      orgName: '',
      small: 'default ',
      orgList: [],
      orgAllList: [],
      drawerwid: '90%',
      formRef: null,
      stockDetail: ref(null),
      visible: false,
      loading: false,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      rowNum: 1,
      type: 2,
      cityDetail: '',
      cityIdDetail: 0,
      searchForm: {
        type: 1,
        rowNum: 1
      },

      totalNum: 0,
      form: {
        quota: '',
        orgId: '',
        num: 0,
        type: 1
      },
      listData: [],
      listInitData: [],
      columns: [
        {
          title: '归属区域',
          dataIndex: 'departureOrgName',
          key: 'departureOrgName',
          slots: {
            customRender: 'departureOrgName'
          }
        },
        {
          title: '总数',
          dataIndex: 'total',
          key: 'total',
          slots: {
            customRender: 'total'
          }
        },
        {
          title: '始发操作量',
          dataIndex: 'startTotal',
          key: 'startTotal',
          ellipsis: true,
          slots: {
            customRender: 'startTotal'
          }
        },
        {
          title: '中转操作量',
          dataIndex: 'transitTotal',
          key: 'transitTotal',
          ellipsis: true,
          slots: {
            customRender: 'transitTotal'
          }
        },
        {
          title: '到达操作量',
          dataIndex: 'endTotal',
          key: 'endTotal',
          ellipsis: true,
          slots: {
            customRender: 'endTotal'
          }
        },
        {
          title: '取车操作量',
          dataIndex: 'pickTotal',
          key: 'pickTotal',
          ellipsis: true,
          slots: {
            customRender: 'pickTotal'
          }
        },
        {
          title: '送车操作量',
          dataIndex: 'deliveryTotal',
          key: 'deliveryTotal',
          ellipsis: true,
          slots: {
            customRender: 'deliveryTotal'
          }
        }

      ]
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = async () => {
      console.log(state.searchForm.orgId)
      if (state.searchForm.orgId !== undefined && state.searchForm.orgId !== null && state.listInitData !== null && state.listInitData.length > 0) {
        state.listData = state.listInitData.filter(item => item.departureOrgId === state.searchForm.orgId)
      } else {
        state.loading = true
        transKanBan({
          ...state.searchForm,
          current: state.pagination.current,
          size: state.pagination.size
        }).then((res) => {
          if (res.code === 10000) {
            state.listData = res.data
            state.listInitData = res.data
          }
        }).finally(() => {
          state.loading = false
        })
      }
    }
    onMounted(loadData)
    const handleChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }

    const exportData = () => {
      stocTransDispatchKanBanExport({ ...state.searchForm }, '发运看板').then((res) => {

      })
    }
    // getOrgList({ orgType: 1 }).then(res => { state.orgList = res.data })
    // getOrgList({}).then(res => { state.orgAllList = res.data })

    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.provinceId = value[0]
      state.searchForm.cityId = value[1]
    }
    const onSwitch = (parameter) => {
      state.visible = parameter
    }
    const onDetail = (type, record) => {
      state.orgName = record.departureOrgName
      if (type === 4) {
        state.rowNum = 1
        state.type = 1
      } else if (type === 5) {
        state.rowNum = 1
        state.type = 3
      } else {
        state.rowNum = type
        state.type = 0
      }

      state.cityDetail = record.nowCity
      state.cityIdDetail = record.cityId * 1

      onSwitch(true)
    }
    return {
      onDetail,
      onSwitch,
      onSearch,
      ...toRefs(state),
      loadData,
      setEndAddress,
      exportData,
      handleChange
    }
  }
}
</script>
