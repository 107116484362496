<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns" >
    <template #search>
      <a-form ref="formQueryRef" :model="searchForm">
        <a-row>
          <a-col :span="4">
            <a-form-item label="城市">
              <a-cascader :options="$store.state.app.cityDict" :changeOnSelect="true" :show-search="{ filter }" @change="setEndAddress" placeholder="请选择" />
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-form-item label="车辆状态">
              <a-select v-model:value="searchForm.vehicleTransStatus" class="vehiclenum_orgId" option-filter-prop="children" show-search :not-found-content="null" allowClear>
                <a-select-option :value=0>全部</a-select-option>
                <a-select-option :value=1>已接单</a-select-option>
                <a-select-option :value=2>已推送</a-select-option>
                <a-select-option :value=3>运输中</a-select-option>
                <a-select-option :value=9>完成</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-form-item label="操作状态">
              <a-select v-model:value="searchForm.rowNum" v-if="searchForm.type === 0" option-filter-prop="children" show-search :not-found-content="null" allowClear>
                <a-select-option :value=1>始发</a-select-option>
                <a-select-option :value=2>中转</a-select-option>
                <a-select-option :value=9>到达</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-form-item label="运输类型">
              <a-select v-model:value="searchForm.vehicleType" v-if="searchForm.type === 0" option-filter-prop="children" show-search :not-found-content="null" allowClear>
                <a-select-option :value=0>全部</a-select-option>
                <a-select-option :value=1>取车</a-select-option>
                <a-select-option :value=2>运车</a-select-option>
                <a-select-option :value=3>送车</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="6">
            <a-form-item label="时间">
              <a-space>
                <a-date-picker v-model:value="searchForm.startTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
                ~
                <a-date-picker v-model:value="searchForm.endTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
              </a-space>
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-form-item label="发运部门">
              <a-select allowClear ref="select" v-model:value="searchForm.orgName" placeholder="归属区域">
                <a-select-option v-for="item in orgList " :key="item.name">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-form-item label="业务来源">
              <a-select allowClear ref="select" v-model:value="searchForm.officeOrgName" placeholder="归属区域">
                <a-select-option v-for="item in orgList " :key="item.name">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-form-item label="派车人">
              <a-input v-model:value="searchForm.departure" />
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-form-item label="委托人">
              <a-input v-model:value="searchForm.customerName" />
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-form-item label="承运商">
              <a-input v-model:value="searchForm.carrierName" />
            </a-form-item>
          </a-col>
          <a-divider type="vertical" />
          <a-col :span="4">
            <a-button type="primary" @click="search">
              <template #icon>
                <SearchOutlined />
              </template>查询
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </template>
    <template #toolbarLeft>
      <a-form-item label="总数">
        <a-tag color="red">{{totalNum}}条</a-tag>
      </a-form-item>
    </template>
    <template #toolbarRight>
      <a-button @click="exportData">
        导出Excel
      </a-button>
    </template>
    <a-table :columns="initColumns" :scroll="{ x: 2500, y: 520}" :row-key="record => record.id" :data-source="listData" :loading="loading" :pagination="pagination" :rowKey="(record,index)=>{return index}" @change="handleChange">
      <template #fileId="{ record }">
        <span v-if="record.fileId !== null">已交车</span>
        <span class="d89f8" v-if="record.fileId === null">未交车</span>
        <span v-if="record.deliveryOfficeOrgName !== null && record.deliveryOfficeOrgName !== ''">{{record.deliveryOfficeOrgName}}</span>
      </template>
    </a-table>
  </suy-table>
</template>
<script>
import { transKanBanPage as transPage, transKanBanDetailExport as transPageExport } from '@/api/dataCenter/stock'
import { toRefs, reactive, onMounted } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import SuyTable from '@/components/SuyTable'
export default {
  props: {
    cityDetail: String,
    orgName: String,
    startTime: String,
    endTime: String,
    orgAllList: Array,
    type: { type: Number, default: null },
    rowNum: { type: Number, default: null },
    cityIdDetail: { type: Number, default: null }
  },
  setup (props) {
    const state = reactive({
      formRef: null,
      loading: false,
      placeholder: props.cityDetail,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        type: 0,
        orgId: '',
        rowNum: props.rowNum,
        vehicleType: props.type,
        vehicleTransStatus: 0,
        orgName: props.orgName,
        startTime: props.startTime,
        endTime: props.endTime,
        cityId: props.cityIdDetail
      },
      totalNum: 0,
      form: {
        quota: '',
        orgId: '',
        num: 0,
        type: 1
      },
      orgList: props.orgAllList,
      listData: [],
      initColumns: [],
      columns: [
        {
          title: '当前总运车次数',
          dataIndex: 'transCount',
          key: 'transCount'
        },
        {
          title: '发运部门',
          dataIndex: 'departureOrgName',
          key: 'departureOrgName'
        },
        {
          title: '派车人',
          dataIndex: 'departure',
          key: 'departure'
        },

        {
          title: '发运时间',
          dataIndex: 'departureTime',
          key: 'departureTime'
        },
        {
          title: '发运城市',
          dataIndex: 'departureCity',
          key: 'departureCity'
        },
        {
          title: '卸车人部门',
          dataIndex: 'unloadingOrgName',
          key: 'unloadingOrgName'
        },
        {
          title: '卸车人',
          dataIndex: 'unloadingOperator',
          key: 'unloadingOperator'
        },
        {
          title: '线路名称',
          dataIndex: 'lineName',
          key: 'lineName'
        },
        {
          title: '类型',
          dataIndex: 'truckType',
          key: 'truckType'
        },
        {
          title: '车辆当前状态',
          dataIndex: 'vehicleTransStatus.label',
          key: 'vehicleTransStatus.label'
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          key: 'carrierName'
        },
        {
          title: '运输车',
          dataIndex: 'carNo',
          key: 'carNo'
        },
        {
          title: '司机',
          dataIndex: 'driverName',
          key: 'driverName'
        },
        {
          title: '推送时间',
          dataIndex: 'pushTime',
          key: 'pushTime'
        },
        {
          title: '合同号',
          dataIndex: 'orderId',
          key: 'orderId'
        },
        {
          title: '车辆品牌',
          dataIndex: 'brand',
          key: 'brand'
        },
        {
          title: '车辆型号',
          dataIndex: 'model',
          key: 'model'
        },
        {
          title: '车架号',
          dataIndex: 'vinNo',
          key: 'vinNo'
        },
        {
          title: '合同金额',
          dataIndex: 'contractAmt',
          key: 'contractAmt'
        },
        {
          title: '利润',
          dataIndex: 'grossProfit',
          key: 'grossProfit'
        },
        {
          title: '利润月份',
          dataIndex: 'profitMonth',
          key: 'profitMonth'
        },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          key: 'startAddress'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress'
        },
        {
          title: '区域来源',
          dataIndex: 'officeOrgName',
          key: 'officeOrgName'
        },
        {
          title: '接单人员',
          dataIndex: 'salesman',
          key: 'salesman'
        },
        {
          title: '委托人',
          dataIndex: 'customerName',
          key: 'customerName'
        },
        {
          title: '委托人电话',
          dataIndex: 'customerMobile',
          key: 'customerMobile'
        },
        {
          title: '委托人类型',
          dataIndex: 'businessType.label',
          key: 'businessType.label'
        }
      ]
    })
    const loadData = async () => {
      state.loading = true
      state.initColumns = state.columns
      console.log('state.searchForm', state.searchForm)
      if (state.searchForm.vehicleType === 1) {
        state.searchForm.rowNum = 1
      } else if (state.searchForm.vehicleType === 3) {
        state.searchForm.rowNum = 1
      } else {
        state.searchForm.vehicleType = 2
      }
      transPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.size
      }).then((res) => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.totalNum = res.data.total
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const handleChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }

    const exportData = () => {
      transPageExport({ ...state.searchForm }, '发运统计-明细').then(res => {
      })
    }
    const setEndAddress = (value, selectedOptions) => {
      state.searchForm.provinceId = value[0]
      state.searchForm.cityId = value[1]
    }
    const detailForm = (searchForm) => {
      // state.searchForm = searchForm

      loadData()
    }
    const search = () => {
      state.pagination.current = 1
      loadData()
    }
    onMounted(loadData)
    return {
      detailForm,
      search,
      ...toRefs(state),
      loadData,
      setEndAddress,
      exportData,
      handleChange
    }
  },
  components: {
    SearchOutlined,
    SuyTable
  }
}
</script>
